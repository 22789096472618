<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="800"
    scrollable
  >
    <v-form ref="form">
      <v-card>
        <v-card-title class="caption">
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4 pt-2">
          <v-row>
            <v-col cols="12">
              <v-data-table
                :loading="isLoading"
                :items-per-page="100"
                :headers="headers"
                :items="items"
                hide-default-footer
              >
                <template v-slot:[`item.no`]="{item,index}">
                  <span>{{ index + 1 }}</span>
                </template>
                <template v-slot:[`item.sequenceNo`]={item,index}>
                  <span :class="selectedRows.includes(item.id) ? 'bg-red' : ''">
                    {{ item.sequenceNo ? item.sequenceNo : index + 1}}
                  </span>
                  <v-btn
                    x-small
                    icon
                    class="ml-2"
                    color="primary"
                    @click="() => selectedRow(item)"
                  >
                    <v-icon>mdi-format-line-spacing</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="items.length">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            @click="submit"
            :loading="isSubmiting"
          >
            {{$_strings.common.SAVE}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from 'axios';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  data() {
    return {
      selectedRows: [],
      groupId: null,
      isLoading: false,
      isSubmiting: false,
      dialog: false,
      items: [],
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          width: '5%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Shipper',
          value: 'companyName',
          sortable: false,
          width: '20%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Lokasi Asal',
          value: 'originLocationName',
          sortable: false,
          width: '20%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Lokasi Tujuan',
          value: 'destinationLocationName',
          sortable: false,
          width: '20%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'No Urut',
          value: 'sequenceNo',
          sortable: false,
          width: '20%',
          class: 'white--text primary text-capitalize',
        },
      ],
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
        return;
      }
      source = CancelToken.source();
      this.fetchData();
    },
  },
  methods: {
    async selectedRow(item) {
      if (this.selectedRows.includes(item.id)) {
        this.selectedRows = this.selectedRows.filter((s) => s !== item.id);
        return;
      }
      if (this.selectedRows.length === 2) return;
      this.selectedRows = [...this.selectedRows, item.id];
      if (this.selectedRows.length === 2) {
        const newItems = [...this.items];
        const findIdx1 = this.items.findIndex((i) => i.id === this.selectedRows[0]);
        const findIdx2 = this.items.findIndex((i) => i.id === this.selectedRows[1]);
        const findItemIdx1 = this.items.find((i) => i.id === this.selectedRows[0]);
        const findItemIdx2 = this.items.find((i) => i.id === this.selectedRows[1]);
        newItems.splice(findIdx1, 1, {
          ...findItemIdx2,
          sequenceNo: findItemIdx1.sequenceNo,
        });
        newItems.splice(findIdx2, 1, {
          ...findItemIdx1,
          sequenceNo: findItemIdx2.sequenceNo,
        });
        this.items = newItems;
        this.selectedRows = [];
      }
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const result = await this.$_services.oracleData.getOrcleOrdersDetail({ groupId: this.groupId });
        this.items = result.data;
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        try {
          this.isSubmiting = true;
          const form = this.items.map((x, i) => ({
            id: x.id,
            sequenceNo: i + 1,
            fastlogOriginId: x.fastlogOriginId,
            fastlogDestinationId: x.fastlogDestinationId,
          }));
          await this.$_services.oracleData.updateOrcaleShipment({ groupId: this.groupId, form, source });
          this.$dialog.notify.success('Berhasil');
          this.dialog = false;
          this.$emit('fetchData');
        } finally {
          this.isSubmiting = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  ::v-deep
    .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:has(.bg-red) {
        background-color: rgba(255, 0, 0, 0.08) !important;
      }
</style>
