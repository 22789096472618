<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-form ref="form">
      <v-card :loading="isLoading">
        <v-card-title class="caption">
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4 pt-2">
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Pesanan Oracle"
                v-model="form.jsonBody"
                outlined
                readonly
                class="caption"
                rows="15"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="showBtnResend">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            @click="submit"
            :loading="isLoading"
          >
            {{$_strings.common.RESEND}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from 'axios';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      showBtnResend: false,
      groupId: null,
      form: {
        jsonBody: '',
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
        this.form = {
          jsonBody: '',
        };
      }
      source = CancelToken.source();
    },
  },
  methods: {
    async submit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        try {
          this.isLoading = true;
          await this.$_services.oracleData.resendLogOracleOrders({ groupId: this.groupId, source });
          this.$dialog.notify.success('Berhasil');
          this.dialog = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
