<template>
  <v-container fluid class="mb-5">
    <v-row>
      <v-col>
        <v-card :class="isMobile ? 'pa-0 ma-0': 'pl-2 pr-2' " elevation="0" tile>
          <v-tabs
            v-model="activeTab"
            background-color="transparent"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.status"
              @click="changePath(tab.status)"
              class="font-weight-bold text-capitalize"
            >
              {{tab.label}}
            </v-tab>
          </v-tabs>
          <v-divider style="margin-top:-2px" />
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <MainList
          :key="key[tab.status]"
          :title="tab.title"
          @refetchData="refetchData"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import MainList from './MainList.vue';

export default {
  name: 'index-oracle-orders',
  components: {
    MainList,
  },
  created() {
    const { status } = this.$route.params;
    const statusValid = ['WAITING', 'CHANGE_ROUTE', 'COMPLETED'];
    if (!statusValid.includes(status)) {
      this.$router.replace({
        params: {
          status: 'WAITING',
        },
      });
    }
  },
  data() {
    return {
      status: '',
      tabs: [
        {
          label: 'Menunggu',
          title: 'Menunggu',
          status: 'WAITING',
        },
        {
          label: 'Ubah Rute',
          title: 'Ubah Rute',
          status: 'CHANGE_ROUTE',
        },
        {
          label: 'Pesanan Dibuat',
          title: 'Pesanan Dibuat',
          status: 'COMPLETED',
        },
      ],
      key: {
        WAITING: 0,
        CHANGE_ROUTE: 0,
        COMPLETED: 0,
      },
      historyPath: {},
    };
  },
  watch: {
    $route: function setHistoryPath(newVal, oldVal) {
      this.historyPath[oldVal.params.status] = oldVal.fullPath;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    activeTab: {
      set() {},
      get() {
        const { status } = this.$route.params;
        return this.tabs.findIndex((e) => e.status === status);
      },
    },
  },
  methods: {
    refetchData() {
      const { status } = this.$route.params;
      this.key[status] += 1;
    },
    changePath(status) {
      const nextTab = this.tabs.findIndex((e) => e.status === status);
      if (nextTab !== this.activeTab) {
        this.$router.replace(this.historyPath[status] || {
          params: {
            status,
          },
        });
      }
    },
  },
};
</script>
